/**
 * Override theme colors
 */

.navbar-default {
    background-color: #E8E8E8;
    border-color: #E8E8E8;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    background-color: #FFFFFF;
    color: #333333;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    background-color: #FFFFFF;
    color: #333333;
}

.navbar-default .navbar-nav > li > a {
    color: #333333;
}

.navbar-default .navbar-brand {
    color: #333333;
}

.navbar {
    border: none;
}

.btn-default:hover {
    background-color: #E8E8E8;
}

.btn-default:active:hover, .btn-default.active:hover, .open > .dropdown-toggle.btn-default:hover, .btn-default:active:focus, .btn-default.active:focus, .open > .dropdown-toggle.btn-default:focus, .btn-default:active.focus, .btn-default.active.focus, .open > .dropdown-toggle.btn-default.focus {
    background-color: #959595;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #383e41;
    background-color: #f3f3f3;
}