html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
}

.card {
    box-shadow: 1px 1px 7px #a5a4a4;
    border-radius: 3px;
    margin: 10px 0 10px 0;
}

.card-block {
    padding: 10px;
}

.img-fluid {
    width: 100%;
}

.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 10px;
}

.form-signin .checkbox {
    font-weight: normal;
}

.form-signin .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.user > div {
    padding-top: 10px;
    box-sizing: border-box;
}

.user-group-list {
    margin-top: 20px;
}

.user-group {
    margin-bottom: 10px;
}

@media (min-width: 1500px) {
    .container {
        width: 1400px;
    }
}

@media (min-width: 1800px) {
    .container {
        width: 1600px;
    }
}

table tr td.key {
    font-weight: bold;
}

.required:after {
    content: "*";
}

h2.entity-title {
    display: inline-block;
}

h2 {
    margin-bottom: 20px;
}

canvas.chart {
    margin: 5px;
}

.value-big {
    font-size: xx-large;
    display: inline;
    vertical-align: bottom;
}

.chart-mini {
    display: inline-block;
    width: 100%;
    height: 50px;
}

.boolean-chart {
    height: 300px;
}

td.timestamp {
    width: 1%;
    white-space: nowrap;
}

td.value {
    font-weight: bold;
}

/**
 * Bootstrap tweaks
 */
.spaced-button {
    margin-bottom: 5px;
}

.container .dl-horizontal dd {
    margin-left: 80px;
}

.container .dl-horizontal dt {
    width: auto;
}

.spaced-button .btn-default {
    width: 100%;
}

@media (min-width: 1024px) {
    .pull-right-lg {
        float: right;
        margin-left: 10px;
    }
}

.group-title {
    font-weight: normal;
    color: #333333;
}

@media (min-width: 768px) {
    .dashboard-setting-form-group {
        min-height: 100%;
        display: flex;
        align-items: flex-end;
    }

}

.vertical-center-children {
    min-height: 100%;
    display: flex;
    align-items: center;
    margin: 5px -15px 5px -15px;
}

.data-node-group-panel > .panel-heading a:before {
    content: '▾';
}

.data-node-group-panel > .panel-heading a.collapsed:before {
    content: '▸';
}

.panel.panel-default.no-parent-panel {
    padding: 0 5px;
    margin-top: -2px;
}

.datanode-value-panel .datanode-value-form-wrapper {
    display: inline-block;
}

.datanode-value-panel .datanode-value-form-wrapper .form-group {
    margin: auto;
}

form[name=numerical_data_node_value] .form-group, form[name=string_data_node_value] .form-group {
    width: 100px;
    display: inline-block;
}

form[name=numerical_data_node_value] .form-group button, form[name=string_data_node_value] .form-group button {
    vertical-align: baseline;
}

.table .btn-default {
    margin-bottom: 2px;
}

.table .details, .table .edit {
    width: 128px;
}

.loady {
    background-position: center center;
    background-image: url('/public/assets/img/loady.gif');
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    left: -40px;
    background-size: cover;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .loady {
        bottom: 15px;
        background-position: left center;
        left: -20px;
        position: relative;
        margin-bottom: 0;
    }
}

.loady:after {
    position: relative;
    left: 40px;
    content: 'Lade...';
    top: 10px
}

.loadbutton {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px 0;
}

@media (min-width: 768px) {
    .loadbutton {
        padding: 0 4px;
        width: auto;
        margin-bottom: 24px;
        margin-left: -20px;
    }
}

/** slider */
.slider {
    margin-top: 15px;
    margin-left: -15px;
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #6CA0CB;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #6CA0CB;
    cursor: pointer;
}

#startDate input{
    transition: all ease-in-out 0.5s;
}

.daily-chart-panel .form-group {
    margin-top: 15px;
    padding-left: 23px; /**  to align it with attribute panel items (above) which have 15px + 8px padding **/
}
